import React, {Component} from 'react';
//import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
//import logo from '../logo.svg';
import '../App.css';

function Present() {
    return (
      <div className="App2">
        <header className="App-header2">          
          <p>
            "This is the vision of the Present of the About page."
          </p>          
        </header>
      </div>
    )
  }
export default Present;