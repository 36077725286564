import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Contact from './contact';
import Contact_Level1 from './contact_level1';
import Contact_Level2 from './contact_level2';
import Contact_Level3 from './contact_level3';
import History from './history';
import Present from './present';
import About from './about';
import Home from './home';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
            <Home />
            </Route>
            <Route exact path="/about">
            <About />
            </Route>  
            <Route exact path="/contact">
            <Contact />
            </Route>
            <Route exact path="/about/history">
            <History />
            </Route>
            <Route exact path="/about/present">
            <Present />
            </Route> 
            <Route exact path="/contact/contact_level1">
            <Contact_Level1 />
            </Route>
            <Route exact path="/contact/contact_level1/contact_level2">
            <Contact_Level2 />
            </Route>
            <Route exact path="/contact/contact_level1/contact_level2/contact_level3">
            <Contact_Level3 />
            </Route>        
        </Switch>
    );    
}

export default Routes;