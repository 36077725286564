import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import logo from '../logo.svg';
import '../App.css';
import Contact_Level1 from './contact_level1';

function Contact() {
    return (
      <div className="App3">
        <header className="App-header3">
          <img src={logo} className="App-logo3" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link3"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
          <ul>
            <li>
              <Link to='Contact/Contact_Level1'>Contact_Level1</Link>
            </li>            
          </ul>
        </header>
      </div>
    )
  }
export default Contact;