import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import './App.css';
import Routes from './components/routes';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}
export default App;

